import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import Tnc from "./Tnc";
import Copy from "./Copy/Copy";

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/term_and_conditions" element={<Tnc />} />
      <Route path="/copy/*" exact element={<Copy />} />
    </Routes>
  );
};

export default Main;
