module.exports = `

<header class="header">
    
    <div class="branding">
        
        <div class="container position-relative">

            <nav class="navbar navbar-expand-lg" >
                <h1 class="site-logo"><a class="navbar-brand" href="/"><img class="logo-icon" src="assets/images/icon.png" style="width: 32px; height: 32px; border-radius: 4px;" alt="logo"> <span class="logo-text">CUSTOM WORKOUT</span></a></h1>
                
                <!-- // Free Version ONLY -->
                <ul class="social-list list-inline mb-0 position-absolute">
                    <li class="list-inline-item"><a class="text-dark" href="https://www.youtube.com/@CustomWorkoutApp"><i class="fab fa-youtube fa-fw"></i></a></li>
                    <li class="list-inline-item"><a class="text-dark" href="https://www.instagram.com/customworkoutapp/"><i class="fab fa-instagram fa-fw"></i></a></li>
                </ul><!--//social-list-->

            </nav>            
        </div><!--//container-->            
    </div><!--//branding-->
</header><!--//header-->


<section class="hero-section">
    <div class="container">
        <div class="row figure-holder">
            <div class="col-12 col-md-6 pt-3 pt-md-4">
                <h2 class="site-headline font-weight-bold mt-lg-5 pt-lg-5">Achieve your goals with a workout app that adapts to your needs.</h2>
                <div class="site-tagline mb-3">Are you striving to achieve your fitness goals while craving a personalized approach? Look no further! Our app is designed to empower you by facilitating the customization of your workout plans, offering real-time audio feedback, and much more.</div>
                <div class="cta-btns">
                    <div class="mb-4"><a class="btn btn-primary font-weight-bold theme-btn" href="https://www.youtube.com/playlist?list=PLG7z15ahL8PfrZgXxtbrgX0cDgk0e5lVB">Get Free Workout Plans</a></div>
                </div>
            </div>
        </div><!--//row-->
    </div>
</section><!--//hero-section-->


       

<footer class="footer theme-bg-primary">
    <div class="download-area py-4">
        <div class="container text-center">
            <h3 class="mb-3">Get the app</h3>
        <div class="section-intro mb-4 single-col-max-width mx-auto">Start your fitness journey today with our feature-rich workout app. Download now and embark on a path to reaching your fitness aspirations!</div>
            <ul class="app-stores list-unstyled list-inline mx-auto  d-inline-block">
                <li class="list-inline-item me-3"><a href="https://apps.apple.com/us/app/id1661788317"><img class="ios" src="assets/images/appstore-apple.svg" alt="app-store"></a></li>
                <li class="list-inline-item"><a href="https://play.google.com/store/apps/details?id=com.ungapps.customworkout"><img class="android" src="assets/images/appstore-android.svg" alt="google play"></a></li>
            </ul>
        </div><!--//container-->
    </div><!--//download-area-->
    <div class="footer-bottom text-center pb-5">
        <!--/* This template is free as long as you keep the footer attribution link. If you'd like to use the template without the attribution link, you can buy the commercial license via our website: themes.3rdwavemedia.com Thank you for your support. :) */-->
        <small class="copyright">Designed with <span class="sr-only">love</span><i class="fas fa-heart" style="color: #fb866a;"></i> by <a href="http://themes.3rdwavemedia.com" target="_blank">Xiaoying Riley</a> for developers</small>
    </div>
    
</footer>

`;
