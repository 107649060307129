import { Helmet, HelmetProvider } from 'react-helmet-async';
import html from './home.html.js';
var htmlDoc = {__html: html};

function Home() {
  return <HelmetProvider>
      <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700|Nunito:400,600,700" rel="stylesheet" />

          <script defer src="assets/fontawesome/js/all.min.js"></script>

          <link rel="stylesheet" href="assets/plugins/flickity/flickity.min.css"/>

          <link id="theme-style" rel="stylesheet" href="assets/css/theme.css" />

          <script type="text/javascript" src="assets/plugins/popper.min.js"/>
          <script type="text/javascript" src="assets/plugins/bootstrap/js/bootstrap.min.js"/>
          <script type="text/javascript" src="assets/plugins/flickity/flickity.pkgd.min.js"/>
          <script type="text/javascript" src="assets/js/main.js"/>
      </Helmet>
      <div dangerouslySetInnerHTML={htmlDoc} />
  </HelmetProvider>;
}

export default Home;
