import React, { useEffect } from "react";
import { isMobile, isAndroid } from "react-device-detect";
import "./styles.css";

const Copy = () => {
  useEffect(() => {
    if (!isMobile) {
      window.location = "/";
    }
  }, []);

  const storeLink = isAndroid
    ? "https://play.google.com/store/apps/details?id=com.ungapps.customworkout"
    : "https://apps.apple.com/us/app/id1661788317";

  return (
    <div class="card center">
      <div class="main-image"></div>
      <div id="content-container">
        <div class="app-title text-bold">Custom Workout with Journal</div>
      </div>
      <div class="input-wrapper">
        <div class="input-container">
          <form class="form-get-the-app">
            <a href={storeLink} class="cta-button text-light">
              Get The App
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Copy;
